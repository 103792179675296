<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-6 col-xxl-6">
        <b-card
          title="Datasets in Unified Format (v0.1)"
          img-src="media/datasets.png"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-text>
            We propose a unified format for storing information in the Document
            Understanding domain and deliver converted datasets as part of the
            released benchmark.
          </b-card-text>
          <b-card-text>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/DocVQA.tar.gz"
              >
                DocVQA
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/DocVQA.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/InfographicsVQA.tar.gz"
              >
                InfographicsVQA
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/InfographicsVQA.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/KleisterCharity.tar.gz"
              >
                KleisterCharity
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/KleisterCharity.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/WikiTableQuestions.tar.gz"
              >
                WTQ
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/WikiTableQuestions.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/PWC.tar.gz"
              >
                PWC
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/PWC.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/DeepForm.tar.gz"
              >
                DeepForm
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/DeepForm.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
            <b-button-group class="ml-2 mt-2">
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/datasets/TabFact.tar.gz"
              >
                TabFact
              </b-button>
              <b-button
                href="https://applica-public.s3.eu-west-1.amazonaws.com/due/pdfs/TabFact.tar.gz"
              >
                (optional) PDFs
              </b-button>
            </b-button-group>
          </b-card-text>
          <b-button
            href="https://github.com/due-benchmark/du-schema"
            variant="primary"
          >
            Format definition
          </b-button>
        </b-card>
      </div>
      <div class="col-lg-6 col-xxl-6">
        <b-card
          title="Baseline Models (v0.1)"
          img-src="media/baseline.png"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-text>
            Since the baselines are finetuned based on the T5 model, you need to
            download either the re-hosted model here (and finetune it on a
            downstream task on your own), or one of the finetuned models.
          </b-card-text>
          <b-card-text>
            <b-button
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/DocVQA-T5-large-2D.tar.gz"
              class="ml-2 mt-2"
            >
              DocVQA T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/DocVQA-T5-large-2D-U.tar.gz"
            >
              DocVQA T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/InfographicsVQA-T5-large-2D.tar.gz"
            >
              InfographicsVQA T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/InfographicsVQA-T5-large-2D-U.tar.gz"
            >
              InfographicsVQA T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/KleisterCharity-T5-large-2D.tar.gz"
            >
              KleisterCharity T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/KleisterCharity-T5-large-2D-U.tar.gz"
            >
              KleisterCharity T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/WTQ-T5-large-2D.tar.gz"
            >
              WTQ T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/WTQ-T5-large-2D-U.tar.gz"
            >
              WTQ T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/PWC-T5-large-2D.tar.gz"
            >
              PWC T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/PWC-T5-large-2D-U.tar.gz"
            >
              PWC T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/DeepForm-T5-large-2D.tar.gz"
            >
              DeepForm T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/DeepForm-T5-large-2D-U.tar.gz"
            >
              DeepForm T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/TabFact-T5-large-2D.tar.gz"
            >
              TabFact T5+2D
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/TabFact-T5-large-2D-U.tar.gz"
            >
              TabFact T5+2D+U
            </b-button>
            <b-button
              class="ml-2 mt-2"
              href="https://applica-public.s3.eu-west-1.amazonaws.com/due/models/T5-large.tar.gz"
            >
              Base T5 model
            </b-button>
          </b-card-text>
          <b-button
            href="https://github.com/due-benchmark/baselines"
            variant="primary"
            class="mt-3"
          >
            Source code
          </b-button>
        </b-card>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
// import Paper from "@/view/content/widgets/due/Paper.vue";

export default {
  name: "leaderboard",
  components: {
    // ListWidget3,
    // StatWidget7,
    // Paper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Datasets and Baseline" }]);
  }
};
</script>
